import React, {Component} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

class NotFoundPage extends Component {
  componentDidMount(){
    if (window){
      window.scrollTo(0,0)
    }
  }

  render(){
    const {classes} = this.props;

    return (
      <div className={classes.root}>
        <h1>404</h1>
        <span>not found</span>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '100vh',
  },
});


export default withStyles(styles)(NotFoundPage);
