import React, {Component} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

class AboutPage extends Component {
  componentDidMount(){
    if (window){
      window.scrollTo(0,0);
    } 
  }

  render(){
    const {
      classes
    } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.imageContainer}>
          <div className={classes.imageBlur}/>
        </div>
        <article>
          <h1>Тебе вітає Лунотека</h1>
          <p>
            Комфортний коворкінг на <a href="http://rex.knu.ua/">факультеті РЕКС</a> КНУ імені Тараса Шевченка.
          </p>
          <p>
            Ми завжди раді тобі: в будь-яку пору року, зранку чи ввечері
            зустрінемо тебе теплом та смачним чаєм.
          </p>
          <p>
            Основна мета та місія коворкінгу — популяризація українських продуктових ІТ-компаній. 
            Саме тому вагому частку нашої щотижневої програми становлять лекції й презентації українських продуктових ІТ компаній. 
            Запорошуємо ми до себе й відомих стартаперів-випусників — тих, хто надихає та мотивує майбутніх Джобсів та Масків діяти!
          </p>
          <p>
            Лунотека не відстає й у розвагах: у будь який час студенти можуть відпочити на кріслах-мішках та пограти в настільні ігри. 
            А по вечорам у нас настільні ігри, клуби ораторського мистецтва та навіть літературні вечори (так, наші студенти дуже цікаві люди).
            Не випадково наш коворкінг називають одним з найтепліших місць корпусів КНУ на ВДНГ:
            достатня кількість обігрівачів, а ще завжди гарячий чай зустічають гостей Лунотеки.
          </p>
          <p>
            До нас приходять навчатися, розвиватися та просто розважатися з друзями. Приходьте й ви. 
            Лунотека відкрита кожного буднього дня з 8:30 до 18:00. Вхід вільний завжди.
          </p>
          <p>
            P.S. А ще ми завжди шукаємо крутих спікерів з українського продуктового ІТ. 
            Тому, якщо це те, чим ви живете або просто де працюєте, і вам є, що сказати студентству, you are welcome!
          </p>
          <p>
            <a href="https://dou.ua/lenta/articles/dou-projector-lunoteka/">Більше про нас</a>
          </p>
        </article>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    minHeight: '100vh',
    margin: `${theme.spacing.unit * 3}px auto`,
    fontSize: '1.2rem',
    [theme.breakpoints.down(1024)]: {
      margin: theme.spacing.unit * 3,
    },
    '@media screen and (min-width: 1024px) and (max-width: 1199px)': {
      width: 960,
    },
    [theme.breakpoints.up(1200)]: {
      width: 1024,
    },
    '& h1': {
      fontSize: '2rem',
      fontWeight: 500,
    },
    '& a': {
      color: theme.palette.common.white,
      '&:hover': {
        color: theme.palette.primary.main,
      }
    },
  },
  imageContainer: {
    width: '100%',
    height: 300,
    minHeight: 300,
    position: 'relative',
    backgroundImage: `url(${require('./intro-image.jpg')})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    [theme.breakpoints.down(1024)]: {
      height: '30vh',
    },
  },
  imageBlur: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    transition: 'background-color 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
});

export default withStyles(styles)(AboutPage);
