import React from 'react';
import Header from './Header';
import Footer from 'components/Footer';
import withStyles from '@material-ui/core/styles/withStyles';

const WithHeaderLayout = ({classes, children}) => (
  <div>
    <Header/>
    <div className={classes.root}>
      <div className={classes.content}>
        {children}
        <Footer/>
      </div>
    </div>
  </div>
);

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 6,
    overflowY: 'auto',
    minHeight: 'calc(100vh - 48px)',
    [theme.breakpoints.down(1024)]: {
      marginBottom: theme.spacing.unit * 6,
      marginTop: 0,
    }
  },
  content: {
    minHeight: 'calc(100vh - 48px)',
    display: 'flex',
    justifyContent: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
});

export default withStyles(styles)(WithHeaderLayout);
