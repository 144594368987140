import React from 'react';
import {Switch, Route, Router} from 'react-router';
import HomePage from 'components/pages/Home';
import GalleryPage from 'components/pages/Gallery';
import AboutPage from 'components/pages/About';
import NotFoundPage from 'components/pages/NotFound';
import MainLayout from 'components/layouts/MainLayout';
import WithHeaderLayout from 'components/layouts/WithHeaderLayout';
import createHistory from 'history/createBrowserHistory';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ff9800',
    },
    background: {
      main: 'black',
    },
    text: {
      primary: 'white',
    }
  },
  typography: {
    fontFamily: 'PT Mono, monospace',
    fontSize: '1rem',
    useNextVariants: true,
    color: 'white',
  }
});

const App = () => (
  <MuiThemeProvider theme={theme}>
    <Router history={createHistory()}>
      <MainLayout>
        <Switch>
          <Route
            exact
            path="/"
            component={HomePage}
          />
          <WithHeaderLayout>
            <Switch>
              <Route
                path="/gallery"
                component={GalleryPage}
              />
              <Route
                path="/about"
                component={AboutPage}
              />
              <Route
                path="*"
                component={NotFoundPage}
              />
            </Switch>
          </WithHeaderLayout>
        </Switch>
      </MainLayout>
    </Router>
  </MuiThemeProvider>
);

export default App;
