import React, {Component} from 'react';
import images from './images';
import './index.scss';

class GalleryPage extends Component {
  componentDidMount(){
    if (window){
      window.scrollTo(0,0)
    } 
  }

  render(){
    return (
      <div className="gallery">
        {
          images.map(
            image => (
              <div 
                className="image-wrapper"
                key={image}
              >
                <img
                  src={image}
                  alt=""
                />
              </div>
            )
          ) 
        }
      </div>
    )
  }
}

export default GalleryPage;
