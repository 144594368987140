import React, {Component} from 'react';
import Event from './Event';
import withStyles from '@material-ui/core/styles/withStyles';

class Feed extends Component {
  constructor(props){
    super(props);

    this.state = {
      feed: [],
      isFetching: true,
    };

    fetch('/feed.json')
      .then(response => response.json())
      .then(response => {
        this.setState({
          feed: response.map(
            event => new Date(event.date) > Date.now()
              ? {
                ...event,
                isUpcoming: true,
              }
              : event
          ),
          isFetching: false
        });
      });
  }

  render(){
    const {
      classes,
    } = this.props;

    const {
      isFetching,
      feed
    } = this.state;

    if (isFetching){
      return (
        <div className={classes.root}>
          ...
        </div>
      )
    }

    return (
      <div className={classes.root}>
        <div className={classes.events}>
          <span className={classes.title}>Майбутні події</span>
          {
            feed
              .filter(event => event.isUpcoming)
              .map((event, index) => (
                <Event
                  key={index}
                  date={event.date}
                  link={event.link}
                  description={event.description}
                />
              )).reverse()
          }
        </div>
        <div className={classes.events}>
          <span className={classes.title}>Минулі події</span>
          {
            feed
              .filter(event => !event.isUpcoming)
              .map((event, index) => (
                <Event
                  key={index}
                  date={event.date}
                  link={event.link}
                  description={event.description}
                />
              ))
          }
        </div>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
    overflowY: 'auto',
    minHeight: '100vh',
  },
  title: {
    display: 'block',
    paddingLeft: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 2,
    fontSize: '1.2rem',
    [theme.breakpoints.down(480)]: {
      fontSize: '1rem',
    },
  },
  events: {
    width: '100%',
  },
});

export default withStyles(styles)(Feed); 
