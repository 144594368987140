import React from 'react';
import {Link} from 'react-router-dom';
import logo from './logo.svg';
import withStyles from '@material-ui/core/styles/withStyles';

const Header = ({classes, ...rest}) => (
  <header 
    className={classes.root} 
    {...rest}
  >
    <Link to="/">
      <img src={logo} alt=""/>
    </Link>
  </header>
);

const styles = theme => ({
  root: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    zIndex: 9999,
    top: 0,
    left: 0,
    width: '50%',
    height: theme.spacing.unit * 6,
    background: theme.palette.common.black,
    [theme.breakpoints.down(1024)]: {
      position: 'relative',
      width: '100%',
    },
    '& a': {
      color: theme.palette.common.white,
      textDecoration: 'none',
      heigth: '100%',
    },
    '& img': {
      height: 50,
      position: 'relative',
      top: - theme.spacing.unit,
      left: theme.spacing.unit,
    },
  },
});

export default withStyles(styles)(Header);
