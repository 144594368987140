import React from 'react';
import Tabs from './Tabs';

const MainLayout = props => (
  <div>
    {props.children}
    <Tabs/>
  </div>
);
export default MainLayout;
