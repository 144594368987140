import React, {Component} from 'react';
import Feed from 'components/Feed';
import Footer from 'components/Footer';
import Logo from './logo.js';
import lunLogo from './lunLogo.svg';

import './index.scss';

const colors = [
  'red',
  'hotpink',
  'blue',
  'yellow',
  'orange',
  'magenta',
  'fuchsia',
  'white',
  'green',
  'aquamarine',
];

class HomePage extends Component {

  componentDidMount(){
    if (typeof window !== 'undefined'){
      window.scrollTo(0,0);
    } 
  }

  state = {
    color: 'white'
  };

  render() {
    return (
      <div className="index">
        <div className="index__left">
          <div className="index__logo">
            <h1 className="index__logo-title">
             <Logo
               fill={this.state.color}
               onClick={
                 () => this.setState({
                   color: colors[Math.floor(Math.random() * 10)]
                 })
               }
             />
            </h1>
            <h2 className="index__logo-subtitle">
              <span>Комфортний коворкінг</span>
              <a href="https://www.lun.ua/"> від <img src={lunLogo} alt="lun.ua"/></a>
            </h2>
            <p style={{display: 'none'}}>
              Коворкінг на факультеті РЕКС КНУ імені Тараса Шевченка
            </p>
            <p style={{display: 'none'}}>
              Ми завжди раді тобі: в будь-яку пору року,
              зранку чи ввечері зустрінемо тебе теплом та смачним чаєм.
            </p>
          </div>
          <div className="index__info">
            <p style={{display: 'none'}}>
              Працюємо з понеділка по п`ятницю,
              з девятої до вісімнадцятої години
            </p>
            Пн-Пт: з 8:30 до 18:00
          </div>
        </div>
        <div className="index__right">
          <div className="index-main-content">
            <main className="index-main-content__inner">
              <Feed/>
              <Footer/>
            </main>
          </div>
        </div>
      </div>
    )
  }
}

export default HomePage;
