import React from 'react';
import MuiTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link} from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router';

const ROUTES = {
  index: {
    value: '/',
    label: 'Головна'
  },
  about: {
    value: '/about',
    label: 'Про нас',
  },
  gallery: {
    value: '/gallery',
    label: 'Галерея',
  },
};

const Tabs = ({classes, location}) => (
  <MuiTabs
    className={classes.root}
    value={location.pathname}
    indicatorColor="primary"
    centered
  >
    {
      Object.values(ROUTES).map((route, index) => (
        <Tab
          label={route.label}
          value={route.value}
          component={Link}
          to={route.value}
          key={index}
        />
      ))
    }
  </MuiTabs>
);

const styles = theme => ({
  root: {
    width: '50%',
    position: 'fixed',
    backgroundColor: 'black', 
    top: 0,
    right: 0,
    [theme.breakpoints.down(1024)]: {
      width: '100%',
      bottom: 0,
      left: 0,
      top: 'unset',
    },
  },
});

const StyledTabs = withStyles(styles)(Tabs);

export default withRouter(StyledTabs);
