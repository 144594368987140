import React from 'react';

export default props => (
  <svg x="0px" y="0px" viewBox="0 0 211.1 63" {...props}>
    <g>
      <path d="M17.3,32.4H9.9c-0.3,5.4-0.6,9.9-1.1,13.5c-0.4,3.6-1.1,6.4-2,8.4c-0.6,1.3-1.3,2.3-2.1,2.9c-0.8,0.6-1.7,0.9-2.6,0.9c-0.5,0-0.9,0-1.2-0.1c-0.3-0.1-0.7-0.2-1-0.3l0.4-2.9c0.3,0.1,0.5,0.1,0.8,0.1c0.4,0,0.9-0.1,1.3-0.4c0.4-0.3,0.9-0.9,1.3-1.7c0.4-0.7,0.7-1.7,1-2.9c0.3-1.2,0.6-2.8,0.9-4.6c0.3-1.9,0.5-4.1,0.6-6.7c0.2-2.6,0.3-5.6,0.4-9.1h13.8v28.5h-3.3V32.4z"/>
      <path d="M35.4,46.3H38l4.8-16.9h3.4l-5.6,18.5c-0.5,1.9-1,3.5-1.6,4.8s-1.1,2.4-1.7,3.2c-0.6,0.8-1.2,1.4-2,1.7c-0.7,0.4-1.5,0.5-2.5,0.5c-0.9,0-1.6-0.1-2.1-0.2c-0.5-0.1-1-0.4-1.4-0.7l1-2.8c0.5,0.3,1,0.5,1.5,0.6c0.4,0.1,0.9,0.1,1.2,0.1c0.7,0,1.5-0.4,2.1-1.3c0.7-0.9,1.3-2.3,1.7-4.4l-3.6,0l-8.5-20h3.8L35.4,46.3z"/>
      <path d="M65.4,44.8H53v13.1h-3.3V29.4H53v12.4h12.4V29.4h3.3v28.5h-3.3V44.8z"/>
      <path d="M73,43.6c0-4.7,0.8-8.4,2.4-10.9c1.6-2.6,4.1-3.8,7.5-3.8c1.8,0,3.4,0.4,4.7,1.1c1.3,0.7,2.3,1.7,3.1,3c0.8,1.3,1.4,2.8,1.7,4.6c0.3,1.8,0.5,3.8,0.5,6c0,4.7-0.8,8.4-2.4,10.9c-1.6,2.6-4.1,3.8-7.6,3.8c-1.8,0-3.4-0.4-4.6-1.1c-1.3-0.7-2.3-1.7-3.1-3c-0.8-1.3-1.4-2.8-1.7-4.6C73.2,47.9,73,45.9,73,43.6z M76.4,43.6c0,1.6,0.1,3.1,0.3,4.5c0.2,1.4,0.5,2.7,1.1,3.7c0.5,1.1,1.2,1.9,2,2.6c0.8,0.6,1.9,1,3.1,1c2.3,0,3.9-1,5-2.9c1-1.9,1.6-4.9,1.6-8.9c0-1.5-0.1-3-0.3-4.5c-0.2-1.4-0.5-2.7-1.1-3.7c-0.5-1.1-1.2-1.9-2-2.6c-0.8-0.6-1.9-1-3.2-1c-2.2,0-3.9,1-4.9,2.9C76.9,36.7,76.4,39.7,76.4,43.6z"/>
      <path d="M96.4,29.4H117v7.5H114v-4.4h-5.7v22.5h4.4v3h-12v-3h4.4V32.4h-5.7v4.4h-2.9V29.4z"/>
      <path d="M122.8,29.4H139v3h-13v9.3h12v3h-12v10.1h13.2v3h-16.5V29.4z"/>
      <path d="M152,44.7h-3v13.2h-3.3V29.4h3.3v12.7h3l8.9-12.7h3.8L154.8,43l11,14.9h-4.2L152,44.7z"/>
      <path d="M183.1,50h-10.6l-2.5,7.9h-3.3l9.3-28.5h3.9l9.3,28.5h-3.4L183.1,50z M173.5,47.1h8.7l-4.1-13.4h-0.5L173.5,47.1z"/>
      <rect x="192.2" y="24.1" width="18.9" height="38.9"/>
    </g>
  </svg>
);
