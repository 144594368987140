import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const MAP_NUMBER_TO_MONTH_NAME = [
  'січня',
  'лютого',
  'березня',
  'квітня',
  'травня',
  'червня',
  'липня',
  'серпня',
  'вересня',
  'жовтня',
  'листопада',
  'грудня',
];

const Event = ({link, date, description, classes}) => {
  const parsedDate = new Date(date);
  const day = parsedDate.getDate();
  const month = MAP_NUMBER_TO_MONTH_NAME[parsedDate.getMonth()]

  return (
    <a
      className={classes.root}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <time className={classes.date}>
        <span className={classes.day}>
          {day}
        </span>
        <span className={classes.month}>
          {month}
        </span>
      </time>
      <div className={classes.description}>
        {description}
      </div>
    </a>
  );
};

const styles = theme => ({
  root: {
    margin: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
        color: theme.palette.primary.main,
    },
  },
  date: {
    border: `1px solid ${theme.palette.text.primary}`,
    margin: theme.spacing.unit,
    padding: theme.spacing.unit,
  },
  day: {
    display: 'block',
    fontSize: 20,
    width: 60,
  },
  month: {
    display: 'block',
    fontSize: 10,
  },
  description: {
    display: 'flex',
    width: '100%',
    color: theme.palette.text.primary,
    fontSize: '1.2rem',
    alignItems: 'center',
    [theme.breakpoints.down(480)]: {
      fontSize: '1rem',
    },
  },
});

export default withStyles(styles)(Event);
