import React from 'react';
import Facebook from './facebook';
import Instagram from './instagram';
import Telegram from './telegram';

import './index.scss';

export default props => (
  <footer className="footer" {...props}>
    <div className="footer-info">
      <h2>Адреса</h2>
      <a
        href="https://goo.gl/maps/pB951AQcjWG2"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>
          Проспект Глушкова, 4г
          <p style={{display: 'none'}}>
            корпус факультету РЕКС КНУ імені Тараса Шевченка
          </p>
        </span>
      </a>
      <h2>Контакти</h2>
      <span>
        Телефон:
        <a href="tel://+380636479969">+380636479969</a>
      </span>
      <h2>Ми в соцмережах</h2>
      <a
        href="https://fb.com/lunoteka"
        title="facebook"
      >
        <Facebook/>
      </a>
      <a
        href="https://t.me/lunoteka"
        title="telegram"
      >
        <Telegram/>
      </a>
      <a
        href="https://instagram.com/lunoteka.knu"
        title="instagram"
      >
        <Instagram/>
      </a>
    </div>
    <div className="footer-map">
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2544.1579215397396!2d30.46821461555213!3d50.3822545794661!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4c8e437459df3%3A0x60b3dc0756b7f205!2z0JvRg9C90L7RgtC10LrQsA!5e0!3m2!1sru!2sua!4v1517750845707"
        frameBorder="0"
        allowFullScreen
      />
    </div>
  </footer>
);
